
import React from 'react'
import Navbar from "./Navbar";
import Form from "./Home/Form/Form";
import './Home/Form/Form.css'
import Map from './Map';
import './contactus.css';
import Contact from "./Home/Contact/Contact";
import Slider from "./Home/slider/Slider";
import CardsHome from "./Home/CardsHome/CardsHome";
import LogoSlidercopy from "./Home/slider/LogoSlidercopy";
import Testimonial from "./Home/Testimonials/Testimonial";







const ContactUs = () => {
  return (



    <div>
      <Navbar />

      <div className="slider_box">
          <Slider />
        </div>
        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>
      

      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

        <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-5">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
      <div className='mt-2 my-3'>
        <div className="our_cus ">
          <div className="achievement_header">
            <div className="our_cus_border"></div>
            <div className="contactus_text my-2">Contact Us</div>
            <div className="our_cus_border"></div>
          </div>
        </div>
      </div>

      <div className="form_box">
        <Form />
      </div>
      <Map />
      {/* <div className="infoss m-4  conatctmargin ">
      <h5 className=' '>Contact Us</h5>
      <div className='flex__contact'>
      <div className="sing first">
        <img src="/Images/home.png" alt="" />
        <a className='address' href="tel:+91-9999963537">Ecole Globale International Girls' School, Near Sahaspur
          Dehradun - 248197, Uttarakahnd, India
        </a>
      </div>

      <div className='second'>
      <div className="sing"><img src="/Images/mail.png" alt="" /><a href="">ecoleglobale@gmail.com</a></div>
      <div className="sing"><img src="/Images/chrome.png" alt="www.ecoleglobale.com" /><a href="">
        www.ecoleglobale.com</a></div>
        </div>
      <div className="sing third"><img src="/Images/phone-call.png" alt="" /><a href="">+91-9557291888</a></div>
    </div>
    <div className="home_copyright">@copyright2022 Ecole Globale</div>
    </div> */}
         <div className="contact_achievement">
        <Contact/>
        </div>
    </div>
  )
}

export default ContactUs