import React from 'react';
import './CardsHome.css'
function CardsHome({top,med,bot}) {
    return (
        <div className='CardsHome'>
            <p>{top}</p>
            <h2>{med}</h2>
            <img src="/Images/fly.png" alt="" />
            <h3>{bot}</h3>
        </div>
    )
}

export default CardsHome;