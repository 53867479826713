import './App.css';
import Home from './Components/Home/Home'; 
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import About from './Components/About';
import React from 'react';
import ModalForm from './Components/Modal/ModalForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Thankyou from './Components/Thankyou';
import Services from './Components/Services';
import Vission from './Components/vission';
import President from './Components/President';
import AddmissionProcedure from './Components/Addmission/Addmission';
import AboutStudentsLife from './Components/Studentslife/AboutStudentsLife';
import ExchangeProgramme from './Components/Studentslife/ExchangeProgramme';
import LiteraryActivities from './Components/Studentslife/LiteraryActivities';
import ArtAndCulture from './Components/Studentslife/ArtAndCulture';
import SportsSchool from './Components/Studentslife/SportsSchool';
import TripsAndExpedition from './Components/Studentslife/TripsAndExpedition';
import CommunityService from './Components/Studentslife/CommunityService';
import OverView from './Components/ PastoralCare/OverView';
import Infirmary from './Components/ PastoralCare/Infirmary';
import DiningHall from './Components/ PastoralCare/DiningHall';
import ContactUs from './Components/ContactUs';



function App() {
  return (
    
    <React.Fragment>
     
  <Router>
      <Routes>
      <Route path="/" element={<Home/>}/>
       <Route path="/about" element={<About/>}/>
       <Route path="/vission" element={<Vission/>}/>
       <Route path="/president" element={<President/>}/>
       <Route path="/addmissionprocedure" element={<AddmissionProcedure/>}/>
       <Route path="/aboutstudentslife" element={<AboutStudentsLife/>}/>
       <Route path="/exchangeprogramme" element={<ExchangeProgramme/>}/>
       <Route path="/literaryactivities" element={<LiteraryActivities/>}/>
       <Route path="/artandculture" element={<ArtAndCulture/>}/>
       <Route path="/sportsschool" element={<SportsSchool/>}/>
       <Route path="/tripsandexpedition" element={<TripsAndExpedition/>}/>
       <Route path="/communityservice" element={<CommunityService/>}/>
       <Route path="/overview" element={<OverView/>}/>
       <Route path="/infirmary" element={<Infirmary/>}/>
       <Route path="/dininghall" element={<DiningHall/>}/>
       <Route path="/services" element={<Services/>}/>
       <Route path="/contactus" element={<ContactUs/>}/>
       <Route path='/Modal' element={<ModalForm/>}/>
       <Route path='/thankyou' element={<Thankyou/>}/>
  </Routes>
  </Router>
  </React.Fragment>
  );
}

export default App;
