import React from 'react'
import './Map.css'

const Map = () => {
  return (
    <div className="mb-map">
    <iframe
      className="map-mb"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13761.293839919292!2d77.8811915!3d30.4269312!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfba63f2a34892007!2s%C3%89cole%20Globale%20International%20Girls%20Schools!5e0!3m2!1sen!2sin!4v1654328185399!5m2!1sen!2sin"
      width="400"
      height="300"
      style={{ border: 0 }}
      allowfullscreen=""
      
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
  )
}

export default Map