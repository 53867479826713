import React from 'react'
import Navbar from "./Navbar";


const Thankyou = () => {
  return (
<>
<Navbar/>
    <div style={{marginTop:'50%'}} className='thank-you'>
        
        <h1 style={{textAlign:'center'}}>Thank you.
             </h1>
             <h4  style={{textAlign:'center'}}>Your form has been Submitted.</h4>
   
      
        </div>
        </>
  )
   
}

export default Thankyou