import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <div className="infoss m-3  conatctmargin ">
      <h5 className=' '>Contact Us</h5>
      <div className='flex__contact'>
      <div className="sing first">
        <img src="/Images/home.png" alt="" />
        <a className='address' href="tel:+91-9999963537">Ecole Globale International Girls' School, Near Sahaspur
          Dehradun - 248197, Uttarakahnd, India
        </a>
      </div>

      <div className='second'>
      <div className="sing"><img src="/Images/mail.png" alt="" /><a href="">ecoleglobale@gmail.com</a></div>
      <div className="sing"><img src="/Images/chrome.png" alt="www.ecoleglobale.com" /><a href="">
        www.ecoleglobale.com</a></div>
        </div>
      <div className="sing third"><img src="/Images/phone-call.png" alt="" /><a href="">+91-9557291888</a></div>
    </div>
    <div className="home_copyright">@copyright2022 Ecole Globale</div>
    </div>
  )
}

export default Contact