import Hamburger from "hamburger-react";
import React from "react";
// import "./vision.css";
import "./students.css";


// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";






const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const TripsAndExpedition = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <>

            <Navbar />

{/* 
            <div className="about-image  ">
                <img src="Images/ecole-globale-trips-and-expedition.jpeg" class="img-fluid  " alt="..." />
            </div> */}
                 {/* <div className="video">
            <ReactPlayer
              style={{ height: '0px', width: '0px' }}
              url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
              playing={true}
              volume={0}
              controls={true}

            />
          </div> */}
           <div className="slider_box">
          <Slider />
        </div>

        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>

      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

            <div className=" container my-3 ">
                <div className="our_about">
                    <div className="about_header">
                        <div className="our_about_border"></div>
                        <div className="trip_text">Trips and Expedition</div>
                        <div className="our_about_border"></div>
                    </div>
                </div>
                {/* <h3 className=" text-xl">About School</h3> */}
                <div className="our_aboutschool">
                    <div className="aboutschool_header">
                        {/* <div className="our_aboutschool_border"></div> */}
                        {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
                        {/* <div className="our_aboutschool_border"></div> */}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div className=''>
                            <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                              
                                The school has partnered with IAYP (International Award for Young People), allowing pupils to participate in annual adventure camps. Rio Resort and Camp Site hosted an IAYP Bronze Level camp this year. The IAYP Camps are characteristic of adventurous campsites, where students learn to live on a limited budget, obtain survival skills, and learn about nature in its most natural state. Tent pitching, swimming, cycling, and obstacle courses such as the Burma Bridge, Commando Net, Rope Ladder, Double Rope Walk, and Spider Web are all popular activities at IAYP Camps. 'Fill the Bucket,' 'Tug of War,' 'Pipes and Marbles,' and 'Perfect Square' are some other games or activities geared at team-building approaches.
                                <br />
                                <br />
                                Expeditions and school travels are essential elements of a child's education. They assist them in gaining self-assurance, self-reliance, and independence. They also allow students to learn about the world around them while exploring their local neighbourhoods.
                                School visits might be informative, entertaining, or both. They educate youngsters about many cultures, ecosystems, and wildlife. Amid the academic year, students have Mid-Term Outings, which consist of a four- or five-day vacation.
                                <br />
                                <br />
                                We prioritise the comfort and safety of every girl travelling on excursions or camps, which is why staff members (both male and female) accompany the girls. In addition, security officers (including lady guards) ensure that females are not left alone when they require safety in unfamiliar areas.
                                The benefits of these trips are twofold: First, girls who do not participate in these expeditions gain valuable skills through observation and participation in the organising process; second, girls who participate benefit from the new experiences and relationships that result from these excursions. The most noteworthy thing about such vacations is that the girls have a great time, take pictures of their unique experiences, and return rested and invigorated.
                                <br />
                                <br />
                                The girls return to the school with a greater desire to take on more challenging tasks, form stronger bonds with their peers, and better understand their skills and weaknesses due to these visits.


                            </p>
                        </div>
                    </div>
                </div>
                {/* <Dropdown/> */}
                <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
                <div className="form_box">
          <Form />
        </div>
            </div>
            <div className="contact_achievement">
        <Contact/>
        </div>
        {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
        </>
    );
};

export default TripsAndExpedition;




