import Hamburger from "hamburger-react";
import React from "react";
// import "./vision.css";
import "./pastrolcare.css";


// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";







const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const Infirmary = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>

      <Navbar />


      {/* <div className="about-image  ">
                <img src="Images/infirmary-header-images.jpeg" class="img-fluid  " alt="..." />
            </div> */}
      {/* <div className="video">
            <ReactPlayer
              style={{ height: '0px', width: '0px' }}
              url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
              playing={true}
              volume={0}
              controls={true}

            />
          </div> */}
      <div className="slider_box">
        <Slider />
      </div>
      <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>



      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

      <div className=" container my-3 ">
        <div className="our_about">
          <div className="about_header">
            <div className="our_about_border"></div>
            <div className="president_text">Infirmary</div>
            <div className="our_about_border"></div>
          </div>
        </div>
        {/* <h3 className=" text-xl">About School</h3> */}
        <div className="our_aboutschool">
          <div className="aboutschool_header">
            {/* <div className="our_aboutschool_border"></div> */}
            {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
            {/* <div className="our_aboutschool_border"></div> */}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className=' '>
              <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                The school have an in-house health care centre with a full-time residential doctor, two nurses and ayahs who work round the clock. The health care centre offers essential first aid treatment and general checkups on request. The school also has a trained physiotherapist who can advise on avoiding injuries while playing sports or during other physical activities.
                Our resident doctor organises eye, ENT, dental and skin checkups periodically. In case of emergencies, we'll rush the patient to the nearest multi-speciality hospital. We also have an ambulance on call 24/7.
              </p>
            </div>
          </div>
        </div>
        {/* <Dropdown/> */}
      </div>
      <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
      <div className="form_box">
        <Form />
      </div>
      <div className="contact_achievement">
        <Contact />
      </div>
      {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
    </>
  );
};

export default Infirmary;



