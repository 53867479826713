import React from "react";
import "./vision.css";
import "react-modern-drawer/dist/index.css";

import "./About.css";
import Navbar from "./Navbar";
import Contact from "./Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "./Home/Form/Form";
import Slider from "./Home/slider/Slider";
import CardsHome from "./Home/CardsHome/CardsHome";
import LogoSlidercopy from "./Home/slider/LogoSlidercopy";
import Testimonial from "./Home/Testimonials/Testimonial";






const Vission = () => {
  // const navigate = useNavigate();
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [popup, setPopup] = React.useState(false);
  // const toggleDrawer = () => {
  //   setIsOpen((prevState) => !prevState);
  // };

  return (
    <>

      <Navbar />


      {/* <div className="about-image  ">
        <img src="Images/schools-in-dehradun-ecole.jpeg" class="img-fluid  " alt="..." />
      </div> */}

      {/* <div className="video">
        <ReactPlayer
          style={{ height: '0px', width: '0px' }}
          url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
          playing={true}
          volume={0}
          controls={true}
        />
      </div> */}
       <div className="slider_box">
          <Slider />
        </div>

        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>


      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>


      <div className=" container my-3 ">
        <div className="our_about">
          <div className="about_header">
            <div className="our_about_border"></div>
            <div className="vission_text">Vision & Mission</div>
            <div className="our_about_border"></div>
          </div>
        </div>
        {/* <h3 className=" text-xl">About School</h3> */}
        <div className="our_aboutschool">
          <div className="aboutschool_header">
            {/* <div className="our_aboutschool_border"></div> */}
            {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
            {/* <div className="our_aboutschool_border"></div> */}
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <div className=" my-3 ">
              <img src="Images/vission_dehradun.jpeg" class="img-fluid  " alt="..." />
              {/* <img src="Images/ecole-globale-international-girls-school.jpeg" class="img-fluid my-3  " alt="..." /> */}

            </div>
          </div>
          <div class="col-lg">
            <div className=' '>
              <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                <div className="vissionschool_text fs-5 mb-3">Vision
                </div>
                At Ecole Globale, we hold a strong notion for uplifting girls, and they are the future. Our vision is to help girls become tomorrow's leaders by providing them with a space where they can learn and grow. We believe every girl should have an equal opportunity to pursue their dreams and live life to its fullest potential. We're committed to doing our part and giving them a chance they wouldn't have had otherwise.
                <br />
                <br />
                <div className="vissionschool_text fs-5 mb-3">Mission
                </div>

                At Ecole Globale, we believe that education is the key to unlocking a bright future. Our mission is to provide an outstanding education to students and excellent professional opportunities for educators in international communities, instilling the knowledge, skills, and character necessary for success in the 21st century.
                <br />
                <br />


                We are committed to building a strong community of educators who can pass on their love of learning and their passion for teaching to students worldwide. We recognize that our students face unique challenges as they learn, so we ensure all of our teachers are highly qualified and experienced professionals with a passion for working with students from diverse backgrounds.
                <br />
                <br />


                We endeavour to provide an environment where all students feel welcomed and valued—they can reach their full potential by joining a supportive community that fosters academic growth while encouraging personal development.
                <br />
                <br />


                Our students have the necessary tools to prepare them for an increasingly competitive world. We believe that the value of education is measured not only by results but also by the learning process itself.


              </p>
            </div>
          </div>
        </div>
        {/* <Dropdown/> */}
      </div>
      
      <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
      <div className="form_box">
          <Form />
        </div>
      <div className="contact_achievement">
        <Contact />
      </div>
      {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
    </>
  );
};

export default Vission;
