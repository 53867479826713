import Hamburger from "hamburger-react";
import React from "react";
import "./Addmission.css";

// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";







const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const AddmissionProcedure = () => {
  // const navigate = useNavigate();
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [popup, setPopup] = React.useState(false);
  // const toggleDrawer = () => {
  //   setIsOpen((prevState) => !prevState);
  // };

  return (
    <>

      <Navbar />


     
       <div className="slider_box">
          <Slider />
        </div>

        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>

        <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

      <div className=" container my-3 ">
        <div className="our_about">
          <div className="about_header">
            <div className="our_about_border"></div>
            <div className="addmission_text ">Admission Procedure</div>
            <div className="our_about_border"></div>
          </div>
        </div>
        {/* <h3 className=" text-xl">About School</h3> */}
        <div className="our_aboutschool">
          <div className="aboutschool_header">
            {/* <div className="our_aboutschool_border"></div> */}
            {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
            {/* <div className="our_aboutschool_border"></div> */}
          </div>
        </div>
        <div class="row">
          <div class="">
            <div className=" my-3 ">
              {/* <img src="Images/vission_dehradun.jpeg" class="img-fluid  " alt="..." /> */}
              {/* <img src="Images/ecole-globale-international-girls-school.jpeg" class="img-fluid my-3  " alt="..." /> */}

            </div>
          </div>
          <div class="col-lg">
            <div className=' '>
              <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                {/* <div className="vissionschool_text fs-5 mb-3">Admission Procedure 

                </div> */}
                The Ecole Globale International Girl's School accepts students in grades IV through XII. The first step in the admissions procedure is to fill out the 'Registration Form' and submit it with the required registration fee and the necessary documents:
                <br />
                <br />

                <div className="container">
                  1. Copy of birth certificate
                  <br />
                  2. Two recent passport-size coloured photographs of the candidate
                  <br />
                  3. Copy of Aadhar card
                  <br />
                  4. Copy of last exam progress report
                  <br />
                  <br />
                </div>

                Following registration, all candidates are assessed on their academic abilities and performance in English, Science, Mathematics, and General Ability, followed by an interaction aimed at determining the unity of the parental point of view with that of the school and interpreting the student's subject scores in terms of their potential, aptitude, personality, intelligence, sporting skills, aesthetics and creative abilities, and any other creation deeds.
                <br />
                <br />

                Failure to attend the events mentioned above will result in a registration lapse. In that case, the registration will be deemed null and void, and the registration fee will be forfeited. Please remember that the admissions committee will be the final arbiter in such cases and may, at its discretion, conduct another test in genuine cases if so justified.
                <br />
                <br />

                The above assessment results are based on merit and are subject to the admission committee's stipulation; subsequent seats will be presented in excellence.
                Note:
                <br />
                <br />


                If you do not attend the interview mentioned above, your registration will be cancelled. In that case, the registration is deemed null and void, and the registration fees are forfeited. Please remember that the admissions committee will be the final arbiter in such cases and may, at its discretion, administer another test in genuine cases if so justified.
                <br />
                <br />

                The above assessment results are firmly based on merit and are subject to the admission committee's stipulation; subsequent seats will be presented on merit.
                <br />
                <br />

                The registration fee is non-refundable, and admission is not guaranteed.

                <br />
                <br />


                {/* <h4>FAQs</h4> */}
                <div className="our_about">
                  <div className="about_header">
                    <div className="our_about_border"></div>
                    <div className="addmission_text">FAQs</div>
                    <div className="our_about_border"></div>
                  </div>
                </div>
                <br />

                <b> Q1. Which are the most popular girls boarding schools in Dehradun?</b>
                <br />
                <br />

                A1. The best girls boarding school in Dehradun is Ecole Globale International Girls' School. The school's 40-acre green campus houses a well-equipped and technologically advanced academic wing, five spacious boarding houses, ample sports amenities, medical facilities, and a messes hall, all of which are architecturally well-planned and constructed.
                <br />
                <br />

                <b> Q2. What makes Ecole Globale the best Boarding in Dehradun?</b>
                <br />
                <br />

                A2. The vast and world-class campus of Ecole Globale has established itself as the top girls boarding school in Dehradun, as well as in India, demonstrating the school's efforts to develop an ideal learning environment for young girls.
                <br />
                <br />

                <b> Q3. What facilities are provided in top International Schools in Dehradun?</b>
                <br />
                <br />

                A3. International schools in Dehradun are well-known for providing children with international-level facilities. Children can access suitable playgrounds, swimming pools, world-class labs, and libraries. These institutions are renowned for providing comprehensive development to students in various areas, including academics, sports, and music.
                <br />
                <br />

                <b>  Q4. What is the curriculum followed in Ecole Globale International Boarding School, Dehradun?</b>
                <br />
                <br />

                A4. The curriculum at Ecole Globale International Boarding School is divided into two types. The Central Board of Secondary Education (CBSE) and Cambridge International Education (CIE) are two student options.
                <br />
                <br />

                <b>  Q5. What is the fee structure in Ecole Globale International Girls Boarding School?</b>
                <br />
                <br />

                A5. .Fees for (India & SAARC):
                <br />
                <br />
                Registration Fee 25,000 INR
                <br />
                Admission Fee is 1,00,000
                <br />
                Caution Deposit is 1,00,000 (refundable)
                <br />
                School Fees - 322244
                <br />
                Boarding Fees - 501482
                <br />
                Imprest A/C - 150000
                <br />
                <br />

                Fees (International): Registration Fee is 750 USD(one time), Admission Fee is 2,000 USD <br /> (one time), Caution Deposit is 2,500 (refundable), School fees is 15,000 USD and Imprest A/C is 1,500 USD.

              </p>
              <p/>
            </div>
          </div>
        </div>
        {/* <Dropdown/> */}
      </div>
      <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
      <div className="form_box">
          <Form />
        </div>
   <br/>
      <div className="contact_achievement">
        <Contact/>
      </div>
      {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
    </>
  );
};

export default AddmissionProcedure;
