import Hamburger from "hamburger-react";
import React from "react";
// import "./vision.css";
import "./students.css";

// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";





const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const SportsSchool = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <>

            <Navbar />

            {/* 
            <div className="about-image  ">
                <img src="Images/ecole-globale-sports-header.jpeg" class="img-fluid  " alt="..." />
            </div> */}

            {/* <div className="video">
                <ReactPlayer
                    style={{ height: '0px', width: '0px' }}
                    url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
                    playing={true}
                    volume={0}
                    controls={true}

                />
            </div> */}

<div className="slider_box">
          <Slider />
        </div>
        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>

      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

            <div className=" container my-3 ">
                <div className="our_about">
                    <div className="about_header">
                        <div className="our_about_border"></div>
                        <div className="president_text">Sports School</div>
                        <div className="our_about_border"></div>
                    </div>
                </div>
                {/* <h3 className=" text-xl">About School</h3> */}
                <div className="our_aboutschool">
                    <div className="aboutschool_header">
                        {/* <div className="our_aboutschool_border"></div> */}
                        {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
                        {/* <div className="our_aboutschool_border"></div> */}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div className='  '>
                            <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                <h2>School</h2>
                                Ecole Globale has won several Inter-School, District, State, Zonal, and National Trophies in numerous sporting activities. Our students have also represented the Ecole and the country internationally, where they participated and gained widespread acclaim. Sports is an activity where one can experience various things, and we are well aware of it. For example, teamwork, winning and losing, good sportsmanship and many more. One can also learn about themselves and their body during sports.
                                <br />
                                <br />

                                The school offers a fully working Rifle Shooting Range that attracts many students. Squash, basketball, and tennis courts are all separate and well-equipped. Ecole Globale also boasts an Olympic-sized pool where students can learn to swim like champions. The school consistently wins many medals in every sports tournament because of our excellent facilities and talented coaches.
                                Volleyball, Table Tennis, and Football facilities are also available at the school, all of which are of the highest standard, with well-trained coaches and fully functional equipment.
                                <br />
                                <br />

                                The school offers five large playgrounds to aid student training while providing equal opportunities for girls to develop their potential and bring out their best. The school band is one of the school's sports as well as a recreational activity. We try to maintain interest in all games throughout the year through several inter-house events.
                                <br />
                                <br />

                                <h3>Our Sports School Offers a Variety of Disciplines</h3>
                                {/* <br /> */}

                                All athletic disciplines are treated equally and are played with zeal under the supervision of specialised coaches. In a nutshell, the domains available are:
                                <br />
                                <br />

                                <ul>
                                    <li className="clubs"> Swimming</li>
                                    <li className="clubs"> Shooting</li>
                                    <li className="clubs"> Badminton</li>
                                    <li className="clubs">  Basketball</li>
                                    <li className="clubs"> Athletics</li>
                                    <li className="clubs">  Football</li>
                                    <li className="clubs">  Tennis</li>
                                    <li className="clubs"> Table Tennis</li>
                                    <li className="clubs"> Squash</li>
                                    <li className="clubs"> MMA (Martial Arts)</li>


                                </ul>


                                {/* <div className="row">
                                    <div className="col">
                                        {
                                            [...new Array(8)].map((val, index) =>
                                                <img key={index} src={`/images/s${index + 3}.jpeg`} alt="" />

                                            )
                                        }
                                    </div>
                                </div> */}








                            </p>
                        </div>
                    </div>
                </div>
                {/* <Dropdown/> */}
                <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
                <div className="form_box">
          <Form />
        </div>
            </div>
            <div className="contact_achievement">
                <Contact />
            </div>
            {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
        </>
    );
};

export default SportsSchool;



