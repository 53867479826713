import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css'




function Form() {
  const [thank, setThank] = React.useState(false);
  const navigate = useNavigate();



  return (
    <>    
    {/* {thank && navigate('/thankyou')} */}
      <form className='Form' action="https://formsubmit.co/8c0235f47842cc2da3238280b698009b" method="POST">

        <div className="form_body">
          <div className="form_header">
            ADMISSION ENQUIRY FORM
          </div>
          <input type="hidden" name="_cc" value="digital360india@gmail.com" ></input>
          <input name='name' placeholder='Full Name*' required />
          <input name='contact' placeholder='Contact Number*' required />
          <input name='email' placeholder='Email Id*' required />
          {/* onClick={()=>setThank(true)} */}
          <button type='submit'  >Submit</button>
        </div>
      </form>
    </>


  )
}

export default Form
