

import Hamburger from "hamburger-react";
import React from "react";
import Contact from "./Home/Contact/Contact";

import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import Form from "./Home/Form/Form";
import styled from "styled-components";
import Slider from "./Home/slider/Slider";
import "./Services.css";
import { useNavigate } from "react-router-dom";

const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const Services = () => {
    const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      {popup && (
        <div className="popup">
          <div className="popup_in">
            <div className="popup_in_in " onClick={() => setPopup(false)}>
              x
            </div>

            <Form/>
          </div>
        </div>
      )}
      <div className="home_icon">
        <div className="Logo">
          <NavLink to='/'><img src="/Images/logo.png" alt=""  /></NavLink>
        </div>
        <div className="Nav-items">
          <a href="tel:+91-9760532480">
            <img className="phone-m" src="./Images/phone-call.png" alt="" />
          </a>
        </div>
        <div className="conn" onClick={toggleDrawer}>
          <a href="#" class="hidden"></a>
          <a href="#" class="hidden"></a>
          <a href="#" class="hidden"></a>
        </div>
        {/* <h4>Dehradun, Uttarakhand</h4> */}
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="top"
        className="blablabla"
      >
        <Menu2 className="Menu">
          <ul>
            <li>
              <NavLink className="linkText" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="linkText " to="/about">
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink className="linkText" to="/services">
                Services
              </NavLink>
            </li>
            <li>
              <NavLink className="linkText" to="/media">
                Media
              </NavLink>
            </li>
            <li>
              <NavLink className="linkText" to="/album">
                Album
              </NavLink>
            </li>
            <li>
              <a className="linkText" href="https://sohnijuneja.com/blog">
                Blog
              </a>
            </li>
            {/* <li>
              <NavLink className="linkText" to="/contact">
                Contact Us
              </NavLink>
            </li> */}
          </ul>
        </Menu2>
      </Drawer>

    
<h1 className="heading">Hello</h1>

    
    </>
  );
};

export default Services;
