import React from "react";
import CardsHome from "./CardsHome/CardsHome";
import Form from "./Form/Form";
import "./Home.css";
import Contact from "./Contact/Contact";
import FAQ from "./FAQS/FAQ";
import Testimonial from "./Testimonials/Testimonial";
import LogoSlider from "./slider/LogoSlider";
import Allocadess from "./Allocades/Allocades";
import Slider from "./slider/Slider";
import ReactPlayer from 'react-player'
import { slide as Menu } from 'react-burger-menu'
import Hamburger from "hamburger-react";
// import component 👇
import "react-modern-drawer/dist/index.css";
import Drawer from 'react-modern-drawer'
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

//import styles 👇
import 'react-modern-drawer/dist/index.css'
// import { Navbar } from "react-bootstra/p";
import Navbar from "../Navbar";
// import { Navbar } from "react-bootstrap";
// import Dropdown from "../Dropdown";
// import Dropdown from "/components/Dropdown";

import { MdStarHalf, MdStar } from 'react-icons/md'


const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;

function Home() {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }
  const navigate = useNavigate();
  // const playing=true;
  return (
    <>
      <Helmet>
        {`
        <script type="application/ld+json">
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Ecole Globale",
          "image": "https://ecoleglobale.in/Images/s7.png",
          "description": "Ecole Globale is undoubtedly the best girls boarding school in Dehradun. This international school is also ranked among the top boarding schools in India.",
          "brand": {
            "@type": "Brand",
            "name": "Best Girls Boarding Schools in India"
          },
          "sku": "446",
          "offers": {
            "@type": "AggregateOffer",
            "url": "https://ecoleglobale.in/",
            "priceCurrency": "INR",
            "lowPrice": ""
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.5",
            "bestRating": "5",
            "worstRating": "1",
            "ratingCount": "446"
          }
        }
        </script>
        `}
        {
          `<script type="application/ld+json">
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Which is the best girls’ boarding school in Dehradun?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ecole Globale International Girls School is the best girls boarding school in Dehradun. The school's beautiful green campus of 40 acres is home to a well-equipped and technology-savvy academic wing, 5 spacious boarding houses, ample sports amenities, medical facilities, and a messes hall, all of which are architecturally well-planned and constructed."
              }
            },{
              "@type": "Question",
              "name": "Why is Dehradun Called Education Hub?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Dehradun has developed in the Education Sector as it has provided many opportunities to the students at an affordable range. Dehradun is the hub of education in India. The city has many renowned educational institutions that are known all over India. Dehradun is home to many prestigious colleges and universities."
              }
            },{
              "@type": "Question",
              "name": "How much do girls' boarding schools cost in Dehradun?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The girls boarding schools provide an affordable range of  Minimum costs for girls' boarding schools in Dehradun. 45,000 per year. Girls' boarding schools in Dehradun may charge more than this on average. Girls’ boarding schools can give them all the facilities that they need to have a great time at school. They have a hostel and plenty of other facilities, which makes it possible to learn and grow at the same time."
              }
            },{
              "@type": "Question",
              "name": "What is the procedure for taking admission to a top girls boarding school in Dehradun?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "To get admission to any of the best boarding schools in Dehradun, you need to fill out the form of your child and then submit it with the school fee and documents. The admissions process at top girls boarding schools in Dehradun is conducted based on scores obtained in the entrance examination, a medical fitness test, and an interview. To be eligible, candidates must pass 10+2 board examinations with at least 60 percent marks in science subjects and 90 percent marks in humanities or social sciences."
              }
            },{
              "@type": "Question",
              "name": "At what age one should send his/her child to a boarding school?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Boarding school can be a great way for your child to learn about themselves and the world around them, but they must be ready for it. According to experts, your child should be at least nine years old before enrolling in boarding school. They must be able to clearly articulate their thoughts and feelings, have strong parental relationships, and have a sense of personal identity."
              }
            },{
              "@type": "Question",
              "name": "What are the advantages of girls boarding schools in India?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Girls boarding schools in India have been helping girls in their personal and professional growth for ages. The advantages of girls boarding schools in India are that they focus on the overall development of a student. The best advantage of girls boarding schools in India is that they provide a safe and secure environment for the girl students to learn and grow. They are far away from the busy town or cities. Boarding schools in India have a very good infrastructure and have modern facilities like classrooms, laboratories, libraries, medical clinics, etc."
              }
            },{
              "@type": "Question",
              "name": "Top 5 Girls Boarding Schools in India?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "1. Ecole Globale International Girls School, Dehradun, Uttrakhand
          2. Welham Girls School - Dehradun, Uttrakhand
          3. Scindia Kanya Vidalaya - Gwalior , Madhya Pradesh 
          4. Heritage Girls’ School, Udaipur, Rajasthan
          5. Mayo College Girls School, Ajmer, Rajasthan"
              }
            }]
          }
          </script>
          
          `
        }
        {`
        <script type="application/ld+json">
        {
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Ecole Globale",
          "url": "https://ecoleglobale.in/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.ecoleglobale.com/blog/{search_term_string}https://www.ecoleglobale.com/contact-us",
            "query-input": "required name=search_term_string"
          }
        }
        </script>`}
        {`
        <script type="application/ld+json">
        {
             "@context": "http://schema.org/",
             "@type": "School",
             "address": {
                  "@type": "PostalAddress",
                  "addressCountry": "india",
                  "addressLocality": "DEHRADUN",
                  "addressRegion": "Uttarakhand",
                  "postalCode": "248197",
                  "streetAddress": "Sahaspur Road, Horawala, Uttarakhand 248197",
                  "telephone": "09557291888",
                  "description": "Ecole Globale is undoubtedly the best girls boarding school in Dehradun. This international school is also ranked among the top boarding schools in India."
             },
             "areaServed": "DEHRADUN",
             "name": "Ecole Globale Girls International School",
             "image": "https://ecoleglobale.in/Images/s7.png",
             "url": "https://ecoleglobale.in/",
             "sameAs": [
                  ""
             ]
        }
        </script>
        `}
        {`
        <script type="application/ld+json">
        {
          "@context": "https://www.schema.org",
          "@type": "EducationalOrganization",
          "mainEntityOfPage": "https://www.ecoleglobale.in",
          "name": "Ecole Globale International Girls' School",
          "url": "https://www.ecoleglobale.in/",
          "member": [
            {
              "@type": "EducationalOrganization",
              "name": "CIE - (Cambridge Assessment International Education)",
              "url": "https://www.cambridgeinternational.org/"
            },
            {
              "@type": "EducationalOrganization",
              "name": "CBSE - (Central Board of Secondary Education)",
              "url": "https://cbse.nic.in/"
            }
          ],
          "sameAs": [
            "https://www.facebook.com/ecoleglobaleinternationalgirlsschool/",
            "https://www.linkedin.com/company/ecole-globale-international-girls-school",
            "https://twitter.com/ecoleglobale",
            "https://www.youtube.com/channel/UCAOXi0p46gOFwEDL1XCO8xA/",
            "https://en.wikipedia.org/wiki/Ecole_Globale_International_Girls'_School",
            "https://www.wikidata.org/wiki/Q29467592"
          ],
          "logo": "https://ecoleglobale.in/Images/logo.png",
          "image": "https://ecoleglobale.in/Images/s7.png",
          "description": "Searching for the best girls boarding school in Dehradun for your child. Ecole Globale is the top and best boarding school. This girls school is affiliated with CIE & CBSE.",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Horawala",
            "addressRegion": "Uttarakhand",
            "addressCountry": "IN",
            "postalCode": "248197",
            "streetAddress": "Sahaspur Road"
          },
          "founder": { "name": "Amarjeet Juneja" }
        }
      </script>
  `}
      </Helmet>
      <div className="Home">

        <Navbar />
        <div className="slider_box">
          <Slider />
        </div>
        <div className="flexbox">
          <div className="flexbox_left">
            <h3>
              <b style={{ color: "#920709" }}>REIMAGINE</b> Education
            </h3>
            <h5>Giving Wings To Dreams</h5>
            <p></p>
          </div>

          <div className="form_box">
            <Form />
          </div>
        </div>

        <div className=" container my-5">
          <div class="row">
            <div class="col-lg">
              <div className=" container ">
                <img src="Images/1_WfCtUtzhi9N-7mwaYGD1wA.jpeg" class="img-fluid rounded-5 " alt="..." />
              </div>
            </div>
            <div class="col-lg">
              <div className=' my-3 '>
                <div className="d-flex">
                  <MdStar size={30} color="#920709" />
                  <MdStar size={30} color="#920709" />
                  <MdStar size={30} color="#920709" />
                  <MdStar size={30} color="#920709" />
                  <MdStarHalf size={30} color="#920709" />
                  <p className=" my-1 mx-1 "> <b>4.4</b> </p>
                  <p  className=" my-1 mx-1"> <b>458 reviews</b> </p>

                </div>
                <p className=" bg-para p-2 mt-4 rounded-1">Ecole Globale is the <b>premier boarding school in Dehradun</b>, Uttarakhand. The school was founded in 2012 by Mr. Amarjeet Juneja former businessman who has the vision to make Ecole Globale into a 'world-class' institution that caters to all strata of society and also provides the best education to young girls to empower them.
                  <br />
                  <br />
                  Ecole Globale is one of the few boarding schools that follow the International Standards of Education and has earned recognition as one of the <b>Best Boarding Schools in India</b>. It offers Grade 4-12 education in English Medium.
                  <br />
                  <br />
                  The school campus is spread across 40 acres of land and comprises all the modern facilities including a library, computer labs, science labs, indoor sports facilities, a swimming pool, a riding arena, and a shooting range. The school has an excellent infrastructure that lies amidst the mountain valley.
                  <br />
                  <br />
                  The hostel has well-equipped rooms with the best amenities. The classrooms too are well-equipped with modern teaching aids like LCD projectors, interactive whiteboards, and many more.
                  <br />
                  <br />
                  What sets Ecole Globale apart from other boarding schools is its foreign exchange programs. This is an excellent opportunity for students who want to learn about other cultures, improve their communication skills, and get exposed to Foreign curriculums.</p>
              </div>
            </div>
          </div>
        </div>


        <div className=" container my-5">
          <div class="row">
            <div class="col-lg">
              <div className=' my-3 '>
                <p className=" bg-para p-2 mt-4 rounded-1">
                  <b>What makes Ecole Globale the Best Girls Boarding school in Dehradun/India?</b>
                  <br />
                  <br />
                  Ecole Globale is the ideal option for parents seeking the top girls boarding school. Ecole Globale is the leading girls boarding school in Dehradun and India for the following main reasons:
                  <br />
                  <br />
                  &#x2022;    World-class education: At Ecole Globale, girls are provided with a top-notch education. The school offers a demanding and engaging academic curriculum that equips students for success in life thanks to its highly qualified and experienced faculty.
                  <br />
                  <br />
                  &#x2022;            State-of-the-art facilities: Students have access to the most recent learning resources thanks to the sophisticated technology in the classrooms, labs, and libraries.
                  <br />
                  <br />
                  &#x2022;              Safe and secure environment: At Ecole Globale, security and safety are given high priority. Students' safety is guaranteed by the school's tight monitoring system and round-the-clock security.
                  <br />
                  <br />
                  &#x2022;             International exposure: By exposing children to different cultures and experiences, the school helps them develop a global perspective. In order to extend their horizons, students take part in international conferences, exchange programs, and other cultural events.
                  <br />
                  <br />
                  &#x2022;              Location:Located in the picturesque city of Dehradun, Ecole Globale is surrounded by the beautiful hills of the Himalayas. The setting of the school offers a calm and serene atmosphere that is ideal for study and personal development.
                </p>

              </div>
            </div>
            <div class="col-lg">
              <div className=" container ">
                <img src="Images/Ecole Globale - Best girls Boarding school in Dehradun and India.png" class="img-fluid rounded-5 " alt="..." />
              </div>
            </div>
          </div>
        </div>


        <div className="our_achievement ">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text">Our Achievements</div>
            <div className="our_achievement_border"></div>
          </div>
          <div className=" container mb-3">
            <p style={{
              textAlign: 'justify',
              textJustify: 'inter-word'
            }}>
              Ecole Globale International Girls School is a renowned boarding school in Dehradun, India, that has received several accolades and achievements over the years. It won the Times Education Icon Awards 2018 for the 'Best Residential Girls School in India’. <b>Ecole Globale was ranked #3 among all Girls Boarding schools in India </b> by Education World India School Rankings 2022-23.
              <br />
              The students of Ecole Globale have also achieved several accomplishments in various fields, including academics, sports, and cultural activities. Some of the notable achievements include winning the All-India Inter-School Swimming Championship, National Basketball Championship, and the National Youth Parliament Competition.

            </p>

          </div>
          <div className="cardsDiv">
            <CardsHome
              top={"Ranked"}
              med={"NO. 1"}
              bot={"Girls Boarding School In INDIA"}
            />
            <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
            <CardsHome
              top={"Secured"}
              med={"100"}
              bot={"Results in CIE AND CBSE Exams"}
            />
          </div>
        </div>
        <div className="our_achievements">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text">Placements</div>
            <div className="our_achievement_border"></div>
          </div>
          <div className=" container mb-3">
            <p style={{
              textAlign: 'justify',
              textJustify: 'inter-word'
            }}>
              What truly makes Ecole Globale a top Girls Boarding school in India, is that the school has a strong record of university placements, with many students securing admission to top universities in India and abroad. Some of the universities that Ecole Globale students have been admitted to include the University of Delhi, the National Institute of Fashion Technology, Jawaharlal Nehru University, Oxford University, Cambridge University, and Harvard University.

            </p>

          </div>
          {/*  */}
          <div className="imgDiv">
            <div className="LastLogo">
              <img src="/Images/img1.png" alt="" />
              <img src="/Images/img2.png" alt="" />
            </div>
            <div className="LastLogo">
              <img src="/Images/University.png" alt="" />
              <img src="/Images/img4.png" alt="" />
            </div>
            <div className="LastLogo">
              <img src="/Images/img6.png" alt="" />
              <img src="/Images/img7.png" alt="" />
              <img src="/Images/img8.png" alt="" />
            </div>
            <div className="LastLogo">
              <img src="/Images/img9.png" alt="" />

              <div className="lastTwologo">
                <img src="/Images/img10.png" alt="" />
                <img src="/Images/img11.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex-lagao"> */}
        <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text my-4 fs-6">Campus & Infrastructure</div>
            <div className="our_achievement_border"></div>
          </div>
          <div className=" container mb-3">
            <p style={{
              textAlign: 'justify',
              textJustify: 'inter-word'
            }}>
              Ecole Globale International Girls School is located on a serene and picturesque campus in Dehradun, India. This highly renowned girls boarding school in Dehradun has state-of-the-art infrastructure that is designed to provide a safe and comfortable environment for students to learn and grow. The academic block of the school is equipped with modern classrooms designed to facilitate effective learning.
              <br />

              Ecole Globale provides comfortable and secure hostel facilities for its students. The hostel rooms are well-furnished and equipped with all modern amenities, including air-conditioning and attached bathrooms. The school has a modern and hygienic dining hall that provides nutritious and healthy meals to students.


            </p>

          </div>
          <div className="videoDiv">
            <ReactPlayer
              style={{ height: '0px', width: '0px' }}
              url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
              playing={false}
              volume={0}
              controls={true}

            />
          </div>
          {/* </div> */}

          <div className="our_achievement">
            <div className="achievement_header">
              <div className="our_achievement_border"></div>
              <div className="achievement_text">Accolades</div>
              <div className="our_achievement_border"></div>
            </div>

            <Allocadess />
          </div>

        </div>

        <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text my-5">Our Associates</div>
            <div className="our_achievement_border"></div>
          </div>
          <div className=" container mb-3">
            <p style={{
              textAlign: 'justify',
              textJustify: 'inter-word'
            }}>
              Ecole Globale International Girls School has established several associations and partnerships with leading organizations and institutions to provide its students with a diverse and enriching educational experience.
              <br />
              This top Girls Boarding school in India is affiliated with the Central Board of Secondary Education (CBSE) and Cambridge International Education (CIE). The school collaborates with several foreign universities, including universities in the USA, UK, Canada, and Australia. These collaborations enable students to participate in exchange programs, summer programs, and other academic initiatives.

            </p>

          </div>
          <LogoSlider />
        </div>

        <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text my-5">Testimonials</div>
            <div className="our_achievement_border"></div>
          </div>
          <Testimonial />
        </div>

        <div className="our_achievement ">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text my-5">FAQ'S</div>
            <div className="our_achievement_border"></div>
          </div>
          <FAQ />
        </div>

        <div className="contact_achievement">
          <Contact />
        </div>
        {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
      </div>
    </>

  );
}

export default Home;
