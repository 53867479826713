import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const items = [
     <div className='Allocades-1'>
          <img  src="./Images/f1.png"  className='img-Allocades'></img>
          <img  src="./Images/f2.png"  className='img-Allocades'></img>
          <img  src="./Images/f3.png"  className='img-Allocades'></img>
    </div>,
     <div className='Allocades-1'>
          <img  src="./Images/f4.png"  className='img-Allocades'></img>
          <img  src="./Images/f5.png"  className='img-Allocades'></img>
          <img  src="./Images/f6.png"  className='img-Allocades'></img>
    </div>,
     <div className='Allocades-1'>
          <img  src="./Images/f7.png"  className='img-Allocades'></img>
          <img  src="./Images/f8.png"  className='img-Allocades'></img>
    </div>,
];

const LogoSlider = () => {
     return (
          <div className="testimonial-slider-contain">
               <AliceCarousel mouseTracking items={items}
                    autoPlay
                    infinite
                    autoPlayInterval="4000"
                    autoPlayStrategy="none"
                    disableButtonsControls />
          </div>
     )
}

export default LogoSlider