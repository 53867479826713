import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const items = [
     <div className='Allocades-1 '>
          <img  src="./Images/img1.png"  className='img-Allocades'></img>
          <img  src="./Images/img2.png"  className='img-Allocades'></img>
          <img  src="./Images/img3.png"  className='img-Allocades'></img>
    </div>,
     <div className='Allocades-1'>
          <img  src="./Images/img4.png"  className='img-Allocades'></img>
          <img  src="./Images/img11.png"  className='img-Allocades'></img>
          <img  src="./Images/img6.png"  className='img-Allocades'></img>
          <img  src="./Images/img11.png"  className='img-Allocades'></img>

    </div>,
     <div className='Allocades-1'>
          <img  src="./Images/img7.png"  className='img-Allocades'></img>
          <img  src="./Images/img8.png"  className='img-Allocades'></img>
          <img  src="./Images/img9.png"  className='img-Allocades'></img>
          <img  src="./Images/img10.png"  className='img-Allocades'></img>

    </div>,
];

const LogoSlider = () => {
     return (
          <div className="testimonial-slider-contain">
               <AliceCarousel mouseTracking items={items}
                    autoPlay
                    infinite
                    autoPlayInterval="4000"
                    autoPlayStrategy="none"
                    disableButtonsControls />
          </div>
     )
}

export default LogoSlider