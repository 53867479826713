import Hamburger from "hamburger-react";
import React from "react";
// import "./vision.css";
import "./students.css";


// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";





const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const LiteraryActivities = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <>

            <Navbar />


            {/* <div className="about-image  ">
                <img src="Images/literary-activities-header.jpeg" class="img-fluid  " alt="..." />
            </div> */}
     {/* <div className="video">
            <ReactPlayer
              style={{ height: '0px', width: '0px' }}
              url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
              playing={true}
              volume={0}
              controls={true}

            />
          </div> */}
 <div className="slider_box">
          <Slider />
        </div>

        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>

      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

            <div className=" container my-3 ">
                <div className="our_about">
                    <div className="about_header">
                        <div className="our_about_border"></div>
                        <div className="president_text">Literary Activities
                        </div>
                        <div className="our_about_border"></div>
                    </div>
                </div>
                {/* <h3 className=" text-xl">About School</h3> */}
                <div className="our_aboutschool">
                    <div className="aboutschool_header">
                        {/* <div className="our_aboutschool_border"></div> */}
                        {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
                        {/* <div className="our_aboutschool_border"></div> */}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div className='  '>
                            <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                Literary activities are an excellent way for students to develop their reading, writing, and speaking skills. Reading is an essential skill that helps students understand what they read. The report helps them express themselves clearly and accurately. Speaking allows them to communicate effectively with others. Apart from school activities, students also participate in conferences and competitions by other reputable institutions, exposing them to various aspects that contribute to their general well-being.
<br/>
<br/>

                                Our pupils participate in the following activities:
                                <br/>
                                <br/>

                                <b>The Dehradun Youth Summit</b> will comprise seminars and plenaries hosted by student leaders and experts who will inspire and share their experiences and ideas on turning national challenges into a force for positive change. This aids pupils in improving their mental processes and seeing diverse challenges in a fresh light.
                                <br/>
                                <br/>

                                <b>In-house Competitions:</b> Competitions such as Just a Minute, Movie Synopsis, Debates, Elocution, Extempore, and many others assist students in improving not only their command of the language but also their reasoning and overall personality. The 'Hindi Saptah,' a week dedicated to promoting and propagating the usage of Hindi, was one of the highlights among many such events held recently. The former consists of various activities aimed at developing pupils' Hindi reading, writing, and speaking abilities.
                                <br/>
                                <br/>

                               <b>Spell Bee: </b> The competition requires pupils to learn spelling and proper sentence usage to improve students the English language.
                                <br/>
                                <br/>
                                The school's Language Department put persistent efforts for students to develop solid linguistic skills in their first, second, and third languages. Students can fully immerse themselves in Literature through competitions and events on and off-campus.

                            </p>
                        </div>
                    </div>
                </div>
                {/* <Dropdown/> */}
                <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
                <div className="form_box">
          <Form />
        </div>
            </div>
            <div className="contact_achievement">
        <Contact/>
        </div>
        {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
        </>
    );
};

export default LiteraryActivities;




