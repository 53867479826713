import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const items = [

     <div className='Allocades-1'>
          <img  src="./Images/x1.png"  className='img-Allocades'></img>
          <img  src="./Images/x2.png"  className='img-Allocades'></img>
          <img  src="./Images/x3.png"  className='img-Allocades'></img>
    </div>,
     <div className='Allocades-1'>
          <img  src="./Images/x4.png"  className='img-Allocades'></img>
          <img  src="./Images/x5.png"  className='img-Allocades'></img>
          <img  src="./Images/x6.png"  className='img-Allocades'></img>
    </div>,
     <div className='Allocades-1'>
          <img  src="./Images/x7.png"  className='img-Allocades'></img>
    </div>,
];

const Allocadess = () => {
     return (
          <div className="testimonial-slider-contain">
               <AliceCarousel mouseTracking items={items}
                    autoPlay
                    infinite
                    autoPlayInterval="4000"
                    autoPlayStrategy="none"
                    disableButtonsControls />
          </div>
     )
}

export default Allocadess