import Hamburger from "hamburger-react";
import React from "react";
// import "./vision.css";
import "./students.css";


// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";





const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const CommunityService = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <>

            <Navbar />

            {/* 
            <div className="about-image  ">
                <img src="Images/students-life-in-dehradun-schools.jpeg" class="img-fluid  " alt="..." />
            </div> */}
            {/* <div className="video">
                <ReactPlayer
                    style={{ height: '0px', width: '0px' }}
                    url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
                    playing={true}
                    volume={0}
                    controls={true}

                />
            </div> */}
             <div className="slider_box">
          <Slider />
        </div>

        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>

      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>




            <div className=" container my-3 ">
                <div className="our_about">
                    <div className="about_header">
                        <div className="our_about_border"></div>
                        <div className="comm_text">Community Service</div>
                        <div className="our_about_border"></div>
                    </div>
                </div>
                {/* <h3 className=" text-xl">About School</h3> */}
                <div className="our_aboutschool">
                    <div className="aboutschool_header">
                        {/* <div className="our_aboutschool_border"></div> */}
                        {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
                        {/* <div className="our_aboutschool_border"></div> */}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div className=' '>
                            <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                Ecoliers have taken significant steps to improve their communities and the world around them. A series of seminars were held at the Government Inter College, Horawala, by students to address the topic of gender inequality and to make the listeners aware of women's empowerment. Many seminars on cleanliness and sanitation have also been held to make a difference in their daily lives.
                                <br />
                                <br />
                                Ecole Globale has also contributed books to the Community Library Project, which aids in the education of the impoverished. Volunteers have not only donated books to the library, but they have also helped to design the interior and exterior of the building. They went even further, sorting and arranging the books.
                                <br />
                                <br />
                                The plastic ban has become popular in Uttarakhand recently, yet most shopkeepers and customers continue to use and demand it. The girls have taken part in some environmental initiatives. Ecole girls took the initiative to educate them by manufacturing 500 paper bags from scratch and distributing them to local businesses. They distributed the bags and educated plastic users about the environmental risks linked with excessive use of the material.
                                <br />
                                <br />
                                Empathy is the ability to understand and share the feelings of another. In today's world, it's more important than ever for children to possess this skill. Empathy has a wide variety of benefits for children, especially young women. They're better able to understand social cues and respond appropriately, more likely to cooperate when someone asks them for help, and better at managing their emotions. Ecoliers have also paid visits to poor people who live in orphanages. When the Ecoliers visited Leonard Cheshire Home in Dehradun, they also approached people with disabilities.
                                <br />
                                <br />
                                The benefits of empathy don't just stop there—they continue through adulthood and can even contribute to a person's success in life. We at Ecole disintegrate these values and raise women who put humanity before anything else.


                            </p>
                        </div>
                    </div>
                </div>
                <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
                {/* <Dropdown/> */}
                <div className="form_box">
                    <Form />
                </div>
            </div>
            <div className="contact_achievement">
                <Contact />
            </div>
            {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
        </>
    );
};

export default CommunityService;








