import React from 'react'
import { NavLink } from 'react-router-dom'
import './Navbar.css';


function Dropdown() {
    return (
        <div>
            <nav class="navbar navbar-expand-lg navbar-light ">
                <div class="container-fluid">
                    <a class="navbar-brand " href="/"> <img className='w-75' src="/Images/logo.png" alt="" /></a>
                    <div className="Nav-items">
                        <a href="tel:+91-9557291888"><i className="fa fa-phone phone-m"></i></a>

                    </div>
                    <button class="navbar-toggler shadow-none  hover " style={{ height: '35px' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="">
                        <span class=""><i class="fa fa-bars"></i></span>
                    </button>
                    <div class="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul class="navbar-nav   ">
                            <li class="nav-item m-lg-auto " style={{padding: '4px'}} >
                                <NavLink class="nav-link active home " aria-current="page" to="/">Home</NavLink>
                            </li>

                            <li class="nav-item dropdown   ">
                                
                                <a class="nav-link dropdown-toggle m-1 active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About
                                </a>
                                
                                <ul class="dropdown-menu border-0  " aria-labelledby="navbarDropdown">
                                    
                                    <li class="nav-item mb-3 mx-2 fw-light ">
                                        <NavLink class="nav-link active " aria-current="page" to="/about">About School</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 mx-2 fw-light ">
                                        <NavLink class="nav-link active " aria-current="page" to="/vission">Vision & Mission</NavLink>
                                    </li>

                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item  mb-3 mx-2 fw-light ">
                                        <NavLink class="nav-link active " aria-current="page" to="/president">President message</NavLink>
                                    </li>
                                </ul>
                            </li>
                            {/* <li class="nav-item dropdown   ">
                                <a class="nav-link dropdown-toggle m-1" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Addmission
                                </a>
                                <ul class="dropdown-menu " aria-labelledby="navbarDropdown">
                                    <li class="nav-item mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/addmissionProcedure">Admission Procedure</NavLink>
                                    </li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li class="nav-item mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/vission">Vision & Mission</NavLink>
                                    </li>

                                    <li><hr class="dropdown-divider" /></li>
                                    <li class="nav-item mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/president">President message</NavLink>
                                    </li>
                                </ul>
                            </li> */}
                             <li class="nav-item m-lg-auto " style={{padding: '4px'}} >
                                <NavLink class="nav-link active home " aria-current="page" to="/addmissionProcedure">Admission</NavLink>
                            </li>
                            <li class="nav-item dropdown   ">
                                <a class="nav-link dropdown-toggle m-1 active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Student Life
                                </a>
                                <ul class="dropdown-menu border-0 " aria-labelledby="navbarDropdown">
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/aboutstudentslife">About Student Life</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/exchangeprogramme">Exchange Programmee</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/literaryactivities">Literary Activities</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/artandculture">Art And Culture</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/sportsschool">Sports School</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/tripsandexpedition">Trips & Expedition</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mb-3 fw-light mx-2 ">
                                        <NavLink class="nav-link active " aria-current="page" to="/communityservice">Community Service</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown   ">
                                <a class="nav-link dropdown-toggle m-1 active" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                PastoralCare
                                </a>
                                <ul class="dropdown-menu border-0 " aria-labelledby="navbarDropdown">
                                    <li class="nav-item mx-2 mb-3 fw-light ">
                                        <NavLink class="nav-link active " aria-current="page" to="/overview">OverView</NavLink>
                                    </li>
                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mx-2 mb-3 fw-light ">
                                        <NavLink class="nav-link active " aria-current="page" to="/infirmary">Infirmary</NavLink>
                                    </li>

                                    {/* <li><hr class="dropdown-divider" /></li> */}
                                    <li class="nav-item mx-2 mb-3 fw-light ">
                                        <NavLink class="nav-link active " aria-current="page" to="/dininghall">Dining Hall</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item m-lg-auto " style={{padding: '4px'}} >
                                <NavLink class="nav-link active home " aria-current="page" to="/contactus">Contact Us</NavLink>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Dropdown