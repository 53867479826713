import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./FAQ.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { textAlign } from "@mui/system";

const FAQ = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function handletoggle() {
    document.querySelector(".LoadMore").style.display = "none";
    document.querySelector(".l6").style.display = "block";
    document.querySelector(".l7").style.display = "block";
  }
  return (
    <>
      <div className="contain">
        {/* <h1>FAQ'S</h1> */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            // display:"none",
            border: "none ",
            borderBottom: "1px solid gray",
            boxShadow: "none",
            width: "88%",
            
          }}
          style={{ width: "88%",
          background: '#eaeaea'
          }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
              boxShadow: "none",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px"
              }}
              variant="h5"
            >
              Which is the best girls’ boarding school in Dehradun?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              Ecole Globale International Girls School is the best girls
              boarding school in Dehradun. The school's beautiful green campus
              of 40 acres is home to a well-equipped and technology-savvy
              academic wing, 5 spacious boarding houses, ample sports amenities,
              medical facilities, and a messes hall, all of which are
              architecturally well-planned and constructed.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{
            border: "none ",
            borderBottom: "1px solid gray",
            boxShadow: "none",
            width: "88%",
          }}
          style={{
          background: '#eaeaea',
          marginTop:'8px'
          }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px"
              }}
              variant="h5"
            >
              Why is Dehradun Called Education Hub?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              Dehradun has developed in the Education Sector as it has provided
              many opportunities to the students at an affordable range.
              Dehradun is the hub of education in India. The city has many
              renowned educational institutions that are known all over India.
              Dehradun is home to many prestigious colleges and universities.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            // display:"none",
            border: "none",
            borderBottom: "1px solid gray",
            boxShadow: "none",
            width: "88%",
          }}
          style={{
            background: '#eaeaea',
            marginTop:'8px'
            }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px"
              }}
              variant="h5"
            >
              {" "}
              How much do girls' boarding schools cost in Dehradun?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              {/* <div className='Divv'>  */}
              The girls boarding schools provide an affordable range of Minimum
              costs for girls' boarding schools in Dehradun. 45,000 per year.
              Girls' boarding schools in Dehradun may charge more than this on
              average. Girls’ boarding schools can give them all the facilities
              that they need to have a great time at school. They have a hostel
              and plenty of other facilities, which makes it possible to learn
              and grow at the same time.
              {/* </div> */}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{
            // display:"none",
            border: "none ",
            borderBottom: "1px solid gray",
            boxShadow: "none",
            width: "88%",
          }}
          style={{
            background: '#eaeaea',
            marginTop:'8px'
            }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px",
              }}
              variant="h5"
            >
              What is the procedure for taking admission to a top girls boarding
              school in Dehradun?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              To get admission to any of the best boarding schools in Dehradun,
              you need to fill out the form of your child and then submit it
              with the school fee and documents. The admissions process at top
              girls boarding schools in Dehradun is conducted based on scores
              obtained in the entrance examination, a medical fitness test, and
              an interview. To be eligible, candidates must pass 10+2 board
              examinations with at least 60 per cent marks in science subjects
              and 90 per cent marks in humanities or social sciences.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          sx={{
            // display:"none",
            border: "none ",
            boxShadow: "none",
            borderBottom: "1px solid gray",
            width: "88%",
          }}
          style={{
            background: '#eaeaea',
            marginTop:'8px'
            }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",

                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px",

                // textAlign: 'inherit'
              }}
              variant="h5"
            >
              {" "}
              What are the advantages of girls boarding schools in India?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              Girls boarding schools in India have been helping girls in their
              personal and professional growth for ages. The advantages of girls
              boarding schools in India are that they focus on the overall
              development of a student. The best advantage of girls boarding
              schools in India is that they provide a safe and secure
              environment for the girl students to learn and grow. They are far
              away from the busy town or cities. Boarding schools in India have
              a very good infrastructure and have modern facilities like
              classrooms, laboratories, libraries, medical clinics, etc.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div className="LoadMore" onClick={handletoggle}>
          <p>Load More</p>
        </div>

        <Accordion
          className="l6"
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{
            // display:"none",
            border: "none ",
            boxShadow: "none",
            borderBottom: "1px solid gray",
            width: "88%",
          }}
          style={{
            background: '#eaeaea',
            marginTop:'8px'
            }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",

                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px",
              }}
              variant="h5"
            >
              {" "}
              At what age one should send his/her child to a boarding school?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              Boarding school can be a great way for your child to learn about
              themselves and the world around them, but they must be ready for
              it. According to experts, your child should be at least nine years
              old before enrolling in boarding school. They must be able to
              clearly articulate their thoughts and feelings, have strong
              parental relationships, and have a sense of personal identity.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="l7"
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{
            // display:"none",
            border: "none ",
            boxShadow: "none",
            borderBottom: "1px solid gray",
            width: "88%",
          }}
          style={{
            background: '#eaeaea',
            marginTop:'8px'
            }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",

                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                marginLeft:"0px",
              }}
              variant="h5"
            >
              {" "}
              Top 5 Girls Boarding Schools in India?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                color: "#464343",
              }}
            >
              <ul className="list">
                <li>
                  1. Ecole Globale International Girls School, Dehradun,
                  Uttrakhand
                </li>
                <li>2. Welham Girls School - Dehradun, Uttrakhand</li>
                <li>3.Scindia Kanya Vidalaya - Gwalior , Madhya Pradesh </li>
                <li>4. Heritage Girls’ School, Udaipur, Rajasthan</li>
                <li>5. Mayo College Girls School, Ajmer, Rajasthan</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accorsrdion> */}
        {/* <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{
            // display:"none",
            border: "none ",
            boxShadow: "none",
            borderBottom: "1px solid gray",
            width: "88%",
          }}
        >
          <AccordionSummary
            sx={{
              color: "gray",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontFamily: "Quicksand",

                fontWeight: "600",
                fontSize: "15px",
                color: "#464343",
                textAlign: "left",
              }}
              variant="h5"
            >
              {" "}
              Top 5 Girls Boarding Schools in India?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* <ul className="list">
                <li>
                  1. Ecole Globale International Girls School, Dehradun,
                  Uttrakhand
                </li>
                <li>2. Welham Girls School - Dehradun, Uttrakhand</li>
                <li>3.Scindia Kanya Vidalaya - Gwalior , Madhya Pradesh </li>
                <li>4. Heritage Girls’ School, Udaipur, Rajasthan</li>
                <li>5. Mayo College Girls School, Ajmer, Rajasthan</li>
              </ul> */}
      </div>

      <div className="mb-map">
        <iframe
          className="map-mb"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13761.293839919292!2d77.8811915!3d30.4269312!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfba63f2a34892007!2s%C3%89cole%20Globale%20International%20Girls%20Schools!5e0!3m2!1sen!2sin!4v1654328185399!5m2!1sen!2sin"
          width="400"
          height="300"
          style={{ border: 0 }}
          allowfullscreen=""
          
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      
    </>
  );
};

export default FAQ;
