import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { data } from '../../../data';
import './Testimonial.css'


const responsive = {
     0: { items: 1 },
     1024: { items: 4 },
   };

const items = [

     <div className='slider-1'>
          <img src={data[0].img} className='img-slider'></img>
          <h2 className='heading-2'>{data[0].name}</h2>
          <p className='para'>{data[0].description}</p>
     </div>,

     <div className='slider-2'>
          <img src={data[1].img} className='img-slider'></img>
          <h2 className='heading-2' >{data[1].name}</h2>
          <p className='para'>{data[1].description}</p>
     </div>,
     <div className='slider-3'>
          <img src={data[2].img} className='img-slider'></img>
          <h2 className='heading-2'>{data[2].name}</h2>
          <p className='para'>{data[2].description}</p>
     </div>,

     <div className='slider-4'>
          <img src={data[3].img} className='img-slider'></img>
          <h2 className='heading-2'>{data[3].name}</h2>
          <p className='para'>{data[3].description}</p>
     </div>



];

const Testimonial = () => {
     return (
          <div className="testimonial-slider-contain">
               <AliceCarousel mouseTracking items={items}
                    autoPlay
                    // infinite
                    autoPlayInterval="3000"
                    // autoPlayStrategy="none"
                    disableButtonsControls 
                    />
          </div>
     )
}

export default Testimonial