import React from 'react'
import AliceCarousel from 'react-alice-carousel';
// import 'react-alice-carousel/lib/alice-carousel.css';
import Form from '../Form/Form';
import './Slider.css'


function Slider() {
  const [popup, setPopup] = React.useState(false);
  const handleDragStart = (e) => e.preventDefault();


  const responsive = {
    0: { items: 1 },
    1024: { items: 3 },
  };

  const items = [

    <div className="modal_action">
      <img className=' ' style={{width:'25em', borderRadius:"23px"}}  src="./Images/ee2ffc08-16b3-49c5-8910-87392577c72f.jpg" role="presentation" onClick={() => setPopup(true)} /></div>
    ,
    <img src="./Images/s2.png" role="presentation" />,
    <img src="./Images/s3.png" role="presentation" />,
    <img src="./Images/s4.png" role="presentation" />,
    <img src="./Images/s5.png" role="presentation" />,
    <img src="./Images/s6.png" role="presentation" />,
    <img src="./Images/s7.png" role="presentation" />,
    <img src="./Images/s8.png" role="presentation" />,
    <img src="./Images/s9.png" role="presentation" />,

  ];
  return (
    <>
      {popup && (
        <div className="popup">
          <div className="popup_in">
            <div className="popup_in_in" onClick={() => setPopup(false)}>
              X
            </div>

            <Form />
          </div>
        </div>
      )}
      <div className="slider-contain  ">
        <AliceCarousel mouseTracking items={items}
          autoPlay
          // infinite
          autoPlayInterval="3000"
          autoPlayStrategy="none"
          disableButtonsControls
          // disableDotsControls
          responsive={responsive} />
      </div>
    </>
  )

}

export default Slider