export const data=[

    {
        name:"Shweta Thakur",
        description:"When I first got a call from Ecole Globale for my job interview I was a bit nervous because it is the best boarding school for girls and I knew that being a part of this renowned institute wasn't that easy. The school holds high standards when it comes to recruiting their staff especially teachers and they accept only the best. After joining here I understood why the school has such high standards. They make no compromise when it comes to curriculum- we provide the best lessons in academic and in the extra-curricular activities. I am extremely thankful to my colleagues for providing such a friendly and supportive professional environment",
        img:"./Images/pp4.png"
    },

    {
        name:"Mr. Pankaj Garg",
        description:"We are from Delhi and it is nothing to hide that pollution there is a big challenge today. I wanted my girls to study in a good school in Dehradun where they understood the value of nature and preserving it so chose the city of Dehradun and the best boarding school for giris-Ecole Globale. Being in a boarding school has taught them confidence and self-sufficiency, see in them the Awerful women they would become tomorrow and the credit goes to the teachers of the school. Every time the vacation is over my daughters leave us with a big smile on their faces",
        img:"./Images/pp1.png"
    },
    {
        name:"Gunisha Arora",
        description:"Ecole Globale- the best boarding school for girl in Dehradun, has been the foundation of what I am as a person today. It taught me lessons that were important for my academics and beyond. It taught me leadership, confidence and improved my sport spirit. My inclination towards architecture was enhanced here and because of the support of my teachers at Ecole, I was able to understand that wanted to build a career in architecture only and I am now successfully running my own architecture firm in Mumbai. Strong schooling is what is needed for a positive career journey and Ecole provides this positivity in the lives of their students.",
        img:"./Images/pp2.png"
    },
    {
        name:"Aradhya Sharma",
        description:"Good teachers are those who never cease to learn themselves and Ecole Globale has been one terrific teacher for my teaching career. It is dedicated towards women empowerment and that is the reason it is the best boarding school for girls in Dehradun. It is dedicated towards women empowerment and it is an absolute honor to be a teacher to girls who are as bright as the stars in the sky. It has been such a great journey of past ten years here and I have to say that the staff here is more than a family to me. Thank you Ecole Globale",
        img:"./Images/pp3.png"
    }
]