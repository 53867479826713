// import Hamburger from "hamburger-react";
import React from "react";
import "./students.css";

// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";





const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const ArtAndCulture = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <>

            <Navbar />


            {/* <div className="about-image  ">
                <img src="Images/Art-Culture.jpeg" class="img-fluid  " alt="..." />
            </div> */}
{/* 
            <div className="video">
                <ReactPlayer
                    style={{ height: '0px', width: '0px' }}
                    url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
                    playing={true}
                    volume={0}
                    controls={true}
                />
            </div> */}
             <div className="slider_box">
          <Slider />
        </div>
        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>

      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>


            <div className=" container my-3 ">
                <div className="our_about">
                    <div className="about_header">
                        <div className="our_about_border"></div>
                        <div className="president_text">Art & Culture</div>
                        <div className="our_about_border"></div>
                    </div>
                </div>
                {/* <h3 className=" text-xl">About School</h3> */}
                <div className="our_aboutschool">
                    <div className="aboutschool_header">
                        {/* <div className="our_aboutschool_border"></div> */}
                        {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
                        {/* <div className="our_aboutschool_border"></div> */}
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg">
                        <div className='  '>
                            <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>


                                Art and culture can help students realise that there are many types of beauty in the world and in life itself. Students will learn about different styles of art as well as different forms of music from around the world.
                                <br />
                                <br />
                                When students learn about art and culture, they can think about how different people might interpret the same piece of art or music differently. They also know about different cultures' traditions and customs, which helps them better understand other people's perspectives.
                                <br />
                                <br />
                                The most crucial benefit of Art and Culture in Schools is that it teaches kids how to be more creative.
                                <br />
                                <br />
                                To help the girls develop their artistic side, the school offers a variety of groups and activities in which they can participate, supervised by professional trainers. The performing arts have always been a favourite of the girls at Ecole Globale, and they have the opportunity to regularly participate in seminars and sessions.
                                <br />
                                <br />
                                The following clubs are available at Ecole Globale:

                                <br />
                                <br />
                                <ul>
                                    <li className="clubs">Music Club</li>
                                    <li className="clubs">Dance Club</li>
                                    <li className="clubs"> Art Club</li>
                                    <li className="clubs">Cookery Club</li>
                                    <li className="clubs"> Poetry Club</li>
                                </ul>








                                The International Awareness Presentation was an event to encourage learning about diverse cultures. We introduced pupils to numerous dance and art forms. These bright minds learn about different countries and receive insights into their cultures. Ecole Globale guarantees that each festival is celebrated with great zeal and that every student is equally involved.
                                The school hosts lively functions every festival to make the ladies feel at ease and at home. Baisakhi and Dussehra celebrations are two of the most eagerly anticipated events in the Ecole community. Similarly, Ganesh Chaturthi and Janmashtami are well-marked within the school grounds. The children are also given a flavour of national and international events commemorating Gandhi Jayanti, Independence Day, Republic Day, Internationalism Day, and Tree Plantation Drives.

                            </p>
                        </div>
                    </div>
                </div>
                <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
                {/* <Dropdown/> */}
                <div className="form_box">
          <Form />
        </div>
            </div>
            <div className="contact_achievement">
                <Contact />
            </div>
            {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
        </>
    );
};

export default ArtAndCulture;



