import Hamburger from "hamburger-react";
import React from "react";
// import "./vision.css";
import "./students.css";


// import Contact from "./Home/Contact/Contact";

// import Map from "./Map";
import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
// import "./About.css";
import { useNavigate } from "react-router-dom";
// import Dropdown from "./Navbar";
// import Navbar from "../Navbar";
import Navbar from "../Navbar";
import Contact from "../Home/Contact/Contact";
// import ReactPlayer from 'react-player'
import Form from "../Home/Form/Form";
import Slider from "../Home/slider/Slider";
import CardsHome from "../Home/CardsHome/CardsHome";
import LogoSlidercopy from "../Home/slider/LogoSlidercopy";
import Testimonial from "../Home/Testimonials/Testimonial";





const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const ExchangeProgramme = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <>

            <Navbar />


            {/* <div className="about-image  ">
                <img src="Images/ecole-globale-trips-and-expedition.jpeg" class="img-fluid  " alt="..." />
            </div> */}

            {/* <div className="video">
                <ReactPlayer
                    style={{ height: '0px', width: '0px' }}
                    url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
                    playing={true}
                    volume={0}
                    controls={true}

                />
            </div> */}
             <div className="slider_box">
          <Slider />
        </div>
        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>


      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

            <div className=" container my-3 ">
                <div className="our_about">
                    <div className="about_header">
                        <div className="our_about_border"></div>
                        <div className="exchange_text">Exchange Programme</div>
                        <div className="our_about_border"></div>
                    </div>
                </div>
                {/* <h3 className=" text-xl">About School</h3> */}
                <div className="our_aboutschool">
                    <div className="aboutschool_header">
                        {/* <div className="our_aboutschool_border"></div> */}
                        {/* <div className="aboutschool_text fs-5">Vision & Mission
</div> */}
                        {/* <div className="our_aboutschool_border"></div> */}
                    </div>
                </div>
                <div class="row">
                    <div class="">
                        {/* <div className=" my-3 ">
                            <img src="Images/amarjeet-juneja.jpeg" class="img-fluid  " alt="..." />
                            <img src="Images/ecole-globale-international-girls-school.jpeg" class="img-fluid my-3  " alt="..." />

                        </div> */}
                    </div>
                    <div class="col-lg">
                        <div className='  '>
                            <p className="  rounded-5 p-1" style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                                {/* <h3>Overview</h3> */}

                                The student exchange is one of the most important programmes we run regularly to help the students become woke and aware of the world around them. The Round Square International Service (RSIS), including students from some of the world's most influential and known institutes, hosts the Ecole Globale.
                                <br />
                                <br />

                                The program allows the girls in our school to meet students from other countries. Moreover, it enables them to visit students abroad.
                                <br />
                                <br />

                                International students that come to our campus for a short time not only broaden their horizons but also provide our girls with the opportunity to learn about a new culture, respect their customs, and, most importantly, gain a better knowledge of the world's problems.
                                No amount of bookish knowledge would help our girls gain the experience they get from such exposure. In this era, the world is interconnected and demands acceptance from all. Therefore, we help them become empathetic and aware human beings.
                                <br />
                                <br />

                                Students are frequently obliged to participate in a community engagement activity as part of the RSIS programme, in which they must work together in a rural location to build infrastructure. These children have previously worked together to construct vital infrastructures such as schools and even teacher housing in a supervised and safe atmosphere.
                                Our girls gain awareness and become mindful of the world's various cultures. Moreover, they understand the issues different communities face and try to find solutions.

                            </p>
                        </div>
                    </div>
                </div>
                <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
                {/* <Dropdown/> */}
                <div className="form_box">
                    <Form />
                </div>
            </div>
            <div className="contact_achievement">
                <Contact />
            </div>
            {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
        </>
    );
};

export default ExchangeProgramme;



