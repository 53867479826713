import Hamburger from "hamburger-react";
import React from "react";

import { NavLink } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
// import Form from "./Home/Form/Form";
import styled from "styled-components";
// import Slider from "./Home/slider/Slider";
import "./About.css";
import { useNavigate } from "react-router-dom";
// import Navbar from './Components/Navbar';

// import { Dropdown } from "/components/Dropdown";
// import { Dropdown } from "react-bootstrap";
import Navbar from "./Navbar";
import Contact from "./Home/Contact/Contact";
import ReactPlayer from 'react-player'
import Form from "./Home/Form/Form";
import Slider from "./Home/slider/Slider";
import CardsHome from "./Home/CardsHome/CardsHome";
import LogoSlidercopy from "./Home/slider/LogoSlidercopy";
import Testimonial from "./Home/Testimonials/Testimonial";



const Menu2 = styled.div`
  width: 100%;
  margin-left: 280px;
  margin-bottom: 50px;


  ul li {
    font-weight: 400;
    font-size: 18px;
    margin: 13.75px;
    font-family: "Roboto";
    font-style: normal;
  }
  @media (max-width: 768px) {
    // display:none;
    margin: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vw;
    background: white;
    transition: 0.3s;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin-left: 40px;
      margin-top: 30px;
      padding: 0;
    }
    li {
      border: none;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      border-right-style: hidden;
      padding: 0;
      border-right-style: hidden !important;
    }
    a {
      margin: 0;
    }
  }
`;
const About = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>

      <Navbar />


      {/* <div className="about-image  ">
        <img src="Images/schools-in-dehradun-ecole.jpeg" class="img-fluid  " alt="..." />
      </div> */}
      {/* <div className="video">
        <ReactPlayer
          style={{ height: '0px', width: '0px' }}
          url="https://firebasestorage.googleapis.com/v0/b/ecoleglobale-597a6.appspot.com/o/Ecole.mp4?alt=media&token=d31ad597-b5a6-43ef-bda3-c5be3cedf31d"
          playing={true}
          volume={0}
          controls={true}

        />
      </div> */}
       <div className="slider_box">
          <Slider />
        </div>
        <div className="our_achievement ">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-4">Our Achievements</div>
          <div className="our_achievement_border"></div>
        </div>
        <div className="cardsDiv">
          <CardsHome
            top={"Ranked"}
            med={"NO. 1"}
            bot={"Girls Boarding School In INDIA"}
          />
          <CardsHome top={"84% Scored"} med={"90%+"} bot={"In 2020-2021"} />
          <CardsHome
            top={"Secured"}
            med={"100"}
            bot={"Results in CIE AND CBSE Exams"}
          />
        </div>
      </div>


      <div className="our_achievement">
          <div className="achievement_header">
            <div className="our_achievement_border"></div>
            <div className="achievement_text p-5 my-1">Placement</div>
            <div className="our_achievement_border"></div>
          </div>
          <LogoSlidercopy />
        </div>

      <div className=" container my-3 ">
        <div className="our_about">
          <div className="about_header">
            <div className="our_about_border"></div>
            <div className="about_text">About Us</div>
            <div className="our_about_border"></div>
          </div>
        </div>
        {/* <h3 className=" text-xl">About School</h3> */}
        <div className="our_aboutschool">
          <div className="aboutschool_header">
            {/* <div className="our_aboutschool_border"></div> */}
            <div className="aboutschool_text fs-5">About School</div>
            {/* <div className="our_aboutschool_border"></div> */}
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <div className="  ">
              <img src="Images/about-ecole-globale-girls-boarding-school.jpeg" class="img-fluid  " alt="..." />
              <img src="Images/ecole-globale-international-girls-school.jpeg" class="img-fluid my-3  " alt="..." />

            </div>
          </div>
          <div class="col-lg">
            <div className=' my-3 '>
              <p className="  rounded-5 p-2" style={{textAlign: 'justify', textJustify: 'inter-word'}} >
                Established in 2012, Ecole Globale International Girls School is a fully - residential girl's school. The school is situated in the educational capital of India, Dehradun and runs under the aegis of the Asian Educational Charitable Trust. This trust also runs The Asian School, a co-educational day and boarding school that is one of the most well-known in the city. While The Asian School has steadily built a reputation as one of the city's most dependable and popular institutions, Ecole Globale is following suit, garnering praise for its role as a premier institute for the holistic development of girls who proudly call themselves 'Ecoliers.'
                <br />
                <br />
                The school made its mark in 2013, just a year after it opened its doors, when it was voted No. 6 in India in the Education World Magazine's 'Peer and Parent Surveys.' The school is over 40 acres over its lush green campus, which is architecturally well-planned and constructed. The school campus houses a well-equipped and technology-savvy academic wing, five spacious boarding houses, amply equipped sports amenities, medical facilities and a messing hall. Ecole Globale, which overlooks the Himalayan Shivalik Range, is a zero-emissions campus that values extreme cleanliness and top-notch care in its academic and non-academic spaces. The serene atmosphere of the campus is perfect for obtaining instruction throughout one's formative years, which is why Ecole is widely regarded as one of India's most beautiful campuses.
                <br />
                <br />
                The school gives options to several educational boards for students to choose from Central Board of Secondary Education (CBSE) and Cambridge International Education (CIE), which are choices made with their previous educational background and future academic goals. As a result of this offering and the many other benefits that the institute provides for its students, the school has a healthy geographical and cultural diversity, with girls from all over the country and the world coming together to form one big Ecole family. Most of the academic and sports faculty live on campus to make the students' after-school hours enjoyable while providing them with quality education in and out of class.

              </p>
            </div>
          </div>
        </div>
        {/* <Dropdown/> */}
        <div className="our_achievement">
        <div className="achievement_header">
          <div className="our_achievement_border"></div>
          <div className="achievement_text my-3">Testimonials</div>
          <div className="our_achievement_border"></div>
        </div>
        <Testimonial />
      </div>
        <div className="form_box">
          <Form />
        </div>
      </div>
      <div className="contact_achievement">
        <Contact />
      </div>
      {/* <div className="home_copyright">@copyright2022 Ecole Globale</div> */}
    </>
  );
};

export default About;
